import React from "react"
import { CustomSection, CustomSubtitle } from "./CustomElements"

const Files = ({ files }) => {
  return (
    <CustomSection>
      <CustomSubtitle className={`text-primary-dark`}>
        Files & Downloads
      </CustomSubtitle>
      <ol className="text-xl list-decimal">
        {files.map(({ asset }) => {
          const { url, originalFilename, id } = asset

          return (
            <li key={`customFile-${id}`} className="blockContent__link">
              <a href={`${url}?dl=`}>{originalFilename}</a>
            </li>
          )
        })}
      </ol>
    </CustomSection>
  )
}

export default Files
