import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"

import BlockContentPrimary from "../components/blockContentPrimary"
import ButtonLink from "../components/ButtonLink"
import Tags from "../components/Tags"
import { CustomSection } from "../components/CustomElements"
import Files from "../components/Files"

const CustomPage = ({ data }) => {
  const {
    id,
    slug,
    files,
    title,
    _rawMainContent: rawMainContent,
  } = data.sanityCustomPages

  return (
    <Layout>
      {/* Change this to be dynamic based on title of job posting */}
      <SEO title={title} />
      {/* Main conent of jobs page */}
      <div className=" flex flex-col items-center mt-10 md:mt-20 mx-3 text-xs">
        <div className=" flex flex-col items-center w-full max-w-6xl text-primary-dark">
          <h1 className="text-center text-primary-dark mt-5 text-5xl md:text-6xl">
            {title}
          </h1>

          <CustomSection className="w-full md:w-3/4">
            <BlockContentPrimary blockData={rawMainContent} />
          </CustomSection>

          {files.length >= 1 && <Files files={files} />}

          <ButtonLink
            text="Home"
            classNames="uppercase text-2xl my-10"
            color="secondary"
            url="/"
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CustomPageQuery($slug: String!) {
    sanityCustomPages(slug: { current: { eq: $slug } }) {
      id
      slug {
        current
      }
      title
      _rawMainContent(resolveReferences: { maxDepth: 10 })
      files {
        asset {
          id
          url
          originalFilename
        }
      }
    }
  }
`

export default CustomPage
