import React from "react"

export const CustomSubtitle = ({ children, className }) => (
  <h2 className={`"text-primary-medium text-center md:text-left ${className}`}>
    {children}
  </h2>
)

export const CustomSection = ({ children, className = "" }) => {
  return <section className={`mt-10 ${className}`}>{children}</section>
}
